import React, { useState } from "react"
import "./dough.css"

const Dough = ({ title, content, copy, date }) => {
  const [copyState, setCopyState] = useState(false)

  const copytext = () => {
    setCopyState(true)
    navigator.clipboard.writeText(copy)
  }

  return (
    <article className="dough">
      <h3 className="pasta-title">{title}</h3>
      <div className="pasta-main">
        <div
          className="pasta-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="pasta-copy">
          <p>Added on {date}</p>
          <button onClick={copytext}>
            {copyState ? "Copied!" : "Click to Copy"}
          </button>
        </div>
      </div>
    </article>
  )
}

export default Dough
