import React from "react"
import Layout from "../components/layout"
import Dough from "../components/pasta-factory/dough"
import Seo from "../components/seo"
import Pasta from "../data/copypasta.json"

const PastaFactory = () => {
  return (
    <Layout cls="pasta">
      <Seo title="MEGA SLACK" />
      <section style={{ width: 1100, margin: "auto", paddingTop: 32 }}>
        {Pasta.map((item, index) => (
          <Dough
            key={index}
            title={item.title}
            content={item.content}
            copy={item.copy}
            date={item.date}
          />
        ))}
      </section>
    </Layout>
  )
}

export default PastaFactory
